import React, { Fragment, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useAppValue } from "../../contexts/Context";
import QueryString from "query-string";
import AdminNavbar from "../../components/navbars/AdminNavbar";
import Transactions from "./transactions/Transactions";
import RecurringPlans from "./plans/recurring_plan/RecurringPlans";
import Contacts from "./contacts/Contacts";
import { allTransactions } from "../../api_client/queries/transactions";
import useGQL from "../../api_client/UseGQL";
import Settings from "./settings/Settings";
import PopOutOverlay from "../../components/overlays/PopOutOverlay";
import TransactionDetails from "./transactions/TransactionDetails";
import RecurringPlanDetails from "./plans/recurring_plan/RecurringPlanDetails";
import ContactDetails from "./contacts/ContactDetails";
import Bill from "./settings/billing/Bill";
import SupportForm from "../../components/forms/SupportForm";
import { withApiValue } from "../../modules/WithApiValue";
import PropTypes from "prop-types";
import { AdminLoader } from "../../components/loaders/AdminLoader";
import Notifications from "./notifications/Notifications";
import Information from "../../components/overlays/Information";
import UserDetails from "./settings/users/UserDetails";
import BankInfo from "./settings/banks/BankInfo";
import * as Sentry from "@sentry/browser";
import { enumValue } from "../../modules/Helpers";
import GenericBanner from "components/banner/GenericBanner";
// import DismissableLinkBanner from "components/banner/DismissableLinkBanner";
import { Button } from "reactstrap";
import CustomGenericBanner from "components/banner/CustomGenericBanner";

// javascript plugin used to create scrollbars on windows
// react plugin for creating notifications over the activity

const config = {
  email: true,
  identifier: true,
  isOwner: true,
  isOwnerOrAdmin: true,
  numberOfAdminsOrOwners: true,
  numberOfValidApprovers: true,
  profile: {
    firstName: true,
    lastName: true,
  },
  company: {
    createdAt: true,
    name: true,
    displayImage: true,
    isUsingConverge: true,
    billingAccount: {
      identifier: true,
    },
    kyc: {
      companyAttestation: true,
      submitted: true,
      updateRequired: true,
      resubmitted: true,
    },
    settings: {
      useSecurityQuestion: true,
      notesRequired: true,
      notesAlias: true,
      requiredPaymentApprovals: true,
    },
    validated: true,
  },
  kyc: {
    submitted: true,
    validated: true,
    updateRequired: true,
    resubmitted: true,
  },
};

const Loader = () => AdminLoader;

function Admin(props) {
  const [context, dispatch] = useAppValue();
  let gqlHooks = useGQL(true);
  const [overlayDetails, setOverlayDetails] = useState(false);
  const location = useLocation();
  const [Component, setComponent] = useState(null);
  const history = useHistory();
  const [fullScreen, setFullScreen] = useState(false);
  const [disableBackdropClick, setDisableBackdropClick] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [Banner, setBanner] = useState(null);

  useEffect(() => {
    dispatch({
      type: "setUser",
      user: {
        identifier: props.identifier,
        isOwner: props.isOwner,
        isOwnerOrAdmin: props.isOwnerOrAdmin,
        company: props.company,
        numberOfAdminsOrOwners: props.numberOfAdminsOrOwners,
        numberOfValidApprovers: props.numberOfValidApprovers,
      },
    });
  }, []);

  //todo get rid of this and handle it a different way
  useEffect(() => {
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("Admin"));
    } catch (e) {
      console.log("sentry not loaded");
    }
    const params = {
      limit: 1,
      offset: 0,
    };
    let output = {
      identifier: true,
    };
    allTransactions(params, output, gqlHooks).then((response) => {
      if (response) {
        dispatch({
          type: "setTransactionCount",
          transactionCount: response.count,
        });
      }
    });

    const draftParams = {
      limit: 1,
      offset: 0,
      states: [enumValue("WAITING_APPROVAL")],
      requiresUserApproval: true,
    };
    let draftOutput = {
      identifier: true,
    };
    allTransactions(draftParams, draftOutput, gqlHooks).then((response) => {
      if (response) {
        dispatch({
          type: "setDraftCount",
          draftCount: response.count,
        });
      }
    });

    const allDraftParams = {
      limit: 1,
      offset: 0,
      states: [enumValue("WAITING_APPROVAL")],
    };
    let allDraftOutput = {
      identifier: true,
    };
    allTransactions(allDraftParams, allDraftOutput, gqlHooks).then(
      (response) => {
        if (response) {
          dispatch({
            type: "setAllDraftCount",
            allDraftCount: response.count,
          });
        }
      }
    );

    allTransactions(draftParams, draftOutput, gqlHooks).then((response) => {
      if (response) {
        dispatch({
          type: "setDraftCount",
          draftCount: response.count,
        });
      }
    });
  }, []);

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.overlay && search.type && search.id) {
      setFullScreen(false);
      if (search.type === "payment") {
        setComponent(
          <TransactionDetails
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      } else if (search.type === "recurring") {
        setComponent(
          <RecurringPlanDetails
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      } else if (search.type === "contact") {
        setComponent(
          <ContactDetails
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      } else if (search.type === "bill") {
        setComponent(
          <Bill
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
        setFullScreen(true);
      } else if (search.type === "bank") {
        setComponent(
          <BankInfo
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      } else if (search.type === "user") {
        setComponent(
          <UserDetails
            toggle={() => history.push({ search: "" })}
            id={search.id}
            setBackdropClick={setDisableBackdropClick}
          />
        );
      }
      setOverlayDetails(true);
    } else if (search.type === "notifications") {
      setComponent(
        <Notifications
          toggle={() => history.push({ search: "" })}
          id={search.id}
          setBackdropClick={setDisableBackdropClick}
        />
      );
      setOverlayDetails(true);
    } else if (search.type === "information") {
      setComponent(
        <Information
          toggle={() => history.push({ search: "" })}
          id={search.id}
        />
      );
      setOverlayDetails(true);
    } else if (search.ticket) {
      setComponent(
        <SupportForm
          isOpen={supportModal}
          toggle={() => history.push({ search: "" })}
        />
      );
      setOverlayDetails(true);
    } else {
      setOverlayDetails(false);
    }
  }, [location.search]);

  useEffect(() => {
    let search = QueryString.parse(location.search);
    if (search.ticket) {
      setSupportModal(true);
    } else {
      setSupportModal(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (!props.company.kyc.submitted || !props.kyc.submitted) {
      history.push("/onboarding");
    } else if (
      (props.isOwner && props.company.kyc.updateRequired) ||
      props.kyc.updateRequired
    ) {
      setBanner(
        <GenericBanner
          title=""
          subtitle="Update your account"
          text={
            props.isOwner && props.company.kyc.updateRequired
              ? "Our records indicate your company information has expired, please update."
              : "Our records indicate your ID has expired, please update."
          }
          actionButton={
            <Button
              className="btn-primary mt-3"
              onClick={() => {
                localStorage.setItem("profileUpdated", "false");
                localStorage.setItem("legalInformationUpdated", "false");
                history.push("/onboarding");
              }}
            >
              Proceed
            </Button>
          }
        />
      );
    } else if (
      (props.isOwner && props.company.kyc.resubmitted) ||
      props.kyc.resubmitted
    ) {
      setBanner(
        <GenericBanner
          title=""
          subtitle="We are reviewing your information"
          text="We will reach out if further information is required. In the meantime, please use Jetpay as usual."
          actionButton={null}
        />
      );
    } else if (!props.kyc.validated || !props.company.validated) {
      setBanner(
        <GenericBanner
          title="Under review"
          subtitle="We are reviewing your information"
          text="You will receive an email confirmation once you have been approved to use Jetpay."
          actionButton={null}
        />
      );
    }
  }, [props.company, props.kyc]);

  return (
    <Fragment>
      {context.alert}
      <div className="wrapper">
        <div className="main-panel">
          <AdminNavbar />
          <PopOutOverlay
            fullscreen={fullScreen}
            disableBackdropClick={disableBackdropClick}
            isOpen={overlayDetails}
            toggle={() => {
              setOverlayDetails(!overlayDetails);
              history.goBack();
            }}
          >
            {Component}
          </PopOutOverlay>
          <div className="announcement-banner-wrapper">{Banner}</div>
          <Switch>
            <Route
              path={["/admin/payments"]}
              exact
              render={() => <Transactions />}
            />
            <Route
              path={["/admin/contacts"]}
              exact
              render={() => <Contacts />}
            />
            <Route
              path={["/admin/plans"]}
              exact
              render={() => <RecurringPlans />}
            />
            <Route path="/admin/contacts" render={() => <Contacts />} />
            <Route path="/admin/settings" render={() => <Settings />} />
            <Redirect from="/admin/*" to="/admin/payments" />
            <Redirect from="/admin" to="/admin/payments" />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
}

export default withApiValue(Admin, config, Loader);

Admin.propTypes = {
  email: PropTypes.string,
  identifier: PropTypes.string,
  isOwner: PropTypes.bool,
  isOwnerOrAdmin: PropTypes.bool,
  profile: PropTypes.object,
  company: PropTypes.object,
  kyc: PropTypes.object,
};
