import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Fade, Row } from "reactstrap";
import AddBankAccount from "../../admin/settings/banks/AddBankAccount";
import OnboardingAddBilling from "./OnboardingAddBilling";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";
import success from "../../../assets/img/onboarding/AddBankCompleteUpdated.svg";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { withApiValue } from "../../../modules/WithApiValue";
import { nextOnboardingPage } from "../../../modules/Helpers";

const config = {
  company: {
    name: true,
    billingAccount: { identifier: true },
    companyType: true,
    bankAccounts: {
      identifier: true,
      institution: true,
      title: true,
      accountNumber: true,
    },
  },
};

function OnboardingAddBankAccount(props) {
  const [bankAccount, setBankAccount] = useState(null);
  const [complete, setComplete] = useState(false);
  const [billingStep, setBillingStep] = useState(false);
  let gqlHooks = useGQL();
  const history = useHistory();

  useEffect(() => {
    trackEvent("onboardingAddBank", {}, gqlHooks);
    if (props.company.billingAccount) {
      setComplete(true);
    } else if (props.company.bankAccounts.length > 0) {
      setBankAccount(props.company.bankAccounts[0]);
    }
  }, []);

  useEffect(() => {
    if (!complete) {
      setBillingStep(!!bankAccount);
    } else {
      setBillingStep(false);
    }
  }, [bankAccount, complete]);

  function Complete() {
    return (
      <Col>
        <div className="text-center">
          <img
            src={success}
            alt="Setup Complete"
            width="100"
            height="100"
            className="m-auto d-block"
          />
          <h2 className="text-center mt-3 mb-4 font-weight-600">
            Bank account added!
          </h2>
        </div>
      </Col>
    );
  }

  return (
    <>
      <Fragment>
        <Fade>
          <Row className="mr-0 ml-0">
            <Col className="m-auto" style={{ maxWidth: 1000 }}>
              {complete ? (
                <Complete />
              ) : bankAccount ? (
                <OnboardingAddBilling
                  setComplete={setComplete}
                  account={bankAccount}
                  handleNext={() => null}
                  update={props.update}
                  name={props.company.name}
                />
              ) : (
                <AddBankAccount
                  setBankAccount={setBankAccount}
                  isOnboarding={true}
                />
              )}
            </Col>
          </Row>
          {!billingStep && complete && (
            <div>
              <Button
                disabled={!complete}
                onClick={() =>
                  history.push(
                    "/onboarding/" +
                      nextOnboardingPage("add-bank", props.kycFlow)
                  )
                }
                className="m-auto d-block mt-4"
                color={complete ? "primary" : "gray-600"}
              >
                Proceed
              </Button>
            </div>
          )}
        </Fade>
      </Fragment>
    </>
  );
}

export default withApiValue(OnboardingAddBankAccount, config);

OnboardingAddBankAccount.propTypes = {
  company: PropTypes.object,
  kycFlow: PropTypes.array,
  update: PropTypes.func,
  loading: PropTypes.bool,
};
